.render-code {
    flex: 1;
    background: #fff;
    padding: 2rem;
    border-radius: 20px;
    overflow: auto;
}

.render-code > pre {
    border-radius: 10px;
}
