:root {
    --outer-whitespace: 20px;
}


body {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    color: #1d1d1f;
    font-style: normal;
}

body.light {
    --bg-color: #35363a;
    --main-bg-color: #f3f7ff;
    --main-text-color: #555;
    --bg-panel-color: #fff;
    --bg-panel-dark-color: #ddd;
    --input-bg: rgba(0, 0, 0, .04)
}

body.dark {
    --bg-color: #35363a;
    --main-bg-color: #202124;
    --main-text-color: #aaa;
    --bg-panel-color: #35363a;
    --bg-panel-dark-color: #1b1c1d;
    --input-bg: rgba(0, 0, 0, .1)
}

* {
    box-sizing: border-box;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
