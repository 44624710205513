.ptaa-header {
    position: fixed;
    top: 10px;
    left: var(--outer-whitespace);
    right: var(--outer-whitespace);

    height: 30px;

    display: flex;
    align-items: center;
    color: #aaa;
}

.ptaa-header path {
    fill: #aaa;
}

.ptaa-header .name {
    margin-left: 6px;
    color: #aaa;
}

.ptaa-header .ptaa-nav {
    flex: 1;
    justify-content: right;
}

.ptaa-header .ptaa-nav ul {
    display: flex;
    justify-content: flex-end;
}

.ptaa-header .ptaa-nav li {
    margin-left: 15px;
    cursor: pointer;
    transition: color 0.25s;
    font-size: 95%;
}

.ptaa-header .ptaa-nav li:hover {
    color: #eee
}

.ptaa-header .theme-toggler {
    border-radius: 10px;
    font-size: 11px;
    padding: 3px 10px;
    width: 60px;
    cursor: pointer;

    transition: background-color 0.25s, color 0.25s, fill 0.25s;
    background: #202124;
    color: #333;

    user-select: none;
}

.ptaa-header .theme-toggler svg {
    position: relative;
    bottom: -2px;
}

.ptaa-header .theme-toggler.dark-theme {
    background: #202124;
    color: #999;
}

.ptaa-header .theme-toggler.dark-theme path {
    fill: #999;
}

.ptaa-header .theme-toggler.light-theme {
    background: #eee;
    color: #333;
}

.ptaa-header .theme-toggler.light-theme path {
    fill: #444;
}

.color-type {
    padding: 5px 0;
}

.color-component-type {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #3F99ED;
    display: inline-block;
}

.color-component-type-parent {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #b61b4c;
    display: inline-block;
}

.color-component-type-name {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #53952D;
    display: inline-block;
}

.color-component-type-text {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #5c3a9e;
    display: inline-block;
}


