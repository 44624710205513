body {
    background: var(--bg-color);
}

.content {
    background: var(--main-bg-color);
    border-radius: 10px;
    overflow: hidden;

    position: fixed;
    top: 48px;
    left: var(--outer-whitespace);
    right: var(--outer-whitespace);
    bottom: var(--outer-whitespace);

    padding: 30px;

    display: flex;
    flex-direction: row;
    transition: background 0.25s;
}

.preview {
    flex: 4;
    display: flex;
}

@media only screen and (max-width: 800px) {
    .preview {
        display: none;
    }

    .content {
        padding: 0;
    }

    .content .editor {
        padding: 0;
    }

    .content .editor .steps {
        border-radius: 0;
        background: var(--bg-panel-dark-color);
    }
}

.editor {
    flex: 3;
    padding-right: 20px;
}

.editor .steps {
    background: var(--bg-panel-color);
    padding: 32px;
    border-radius: 10px;
}

.editor .mic {
    list-style: none;
    margin-top: 15px;
}

.editor .step {
    padding-top: 0.6rem;
    line-height: 1.7;
}

.editor .step:first-of-type {
    padding-top: 0;
}

.editor .step input {
    border: none;
    outline: none;
    color: var(--main-text-color);
    background: var(--input-bg);
    border-radius: 4px;
}

.editor .step {
    transition: color 0.25s;
    color: var(--main-text-color);
}

.editor .attr-text::before,
.editor .attr-text::after {
    content: '"';
}

.editor .component-type,
.editor .component-type-and-name,
.editor .attr-name,
.editor .attr-text,
.editor .attr-classNames,
.editor .attr-value {
    color: #fff;
    padding: 3px 5px;
    border-radius: 4px;
}

.editor .component-type {
    background-color: #3F99ED;
}

.editor .component-type-and-name {
    background-color: #b61b4c;
}

.editor .attr-name {
    background-color: #53952D;
}

.editor .attr-text {
    background-color: #5c3a9e;
}

.editor .attr-classNames {
    background-color: #ED8E3F;
}

.editor .attr-value {
    background-color: #ED8E3F;
}


/* */

.dictaphone {
    display: flex;
    color: var(--main-text-color);
    fill: var(--main-text-color);
    font-size: 90%;
    margin-left: 8px;
}

.dictaphone.active {
    color: #3F99ED;
}

.dictaphone .icon {
    margin-right: 8px;
    position: relative;
    left: 3px;
    bottom: -1px;
    width: 14px;
    cursor: pointer;
}

.dictaphone .icon.active path {
    fill: #3F99ED;
    position: relative;
}

.dictaphone .icon.active::before {
    content: "";
    width: 4px;
    height: 8px;
    background: #3F99ED;
    display: inline-block;
    position: absolute;
    z-index: -1;
    top: 1px;
    left: 5px;
    border-radius: 20%;
    opacity: 0.8;
}

.dictaphone .info {
    flex: 1;
    opacity: 0.7;
}

.dictaphone .toggle-btn {
    background-color: var(--bg-panel-color);
    display: inline-block;
    border-radius: 5px;
    padding: 2px 6px;
    margin: -2px 0;
    cursor: pointer;
}

.render-preview {
    position: relative;
}

.modal.static {
    position: relative;
    padding: 3.6rem;
}

.modal-backdrop.static {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.backdrop-example-container {
    position: relative;
    padding: 1.2rem;
}
