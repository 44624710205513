.render-ui {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.render-ui .render-preview {
    flex: 1;
    background: #fff;
    padding: 2rem;
    border-radius: 20px;
    overflow: hidden;
    transition: background-color 0.25s;
}

.render-ui .render-preview.show-dark-background {
    background: #141516;
}

.render-ui .render-tools {
    height: 20px;
    margin-top: 10px;
    font-size: 90%;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #666;
    user-select: none;
}

.render-ui .render-tools label {
    margin-right: 10px;
    cursor: pointer;
}

.render-ui .render-tools label input {
    position: relative;
    bottom: -2px;
}

.render-ui .show-outline [id] {
    box-shadow: 0 0 0 1px #3F99ED;
    position: relative;
}

.render-ui .show-name [id]::before {
    content: attr(id);
    position: absolute;
    top: -17px;
    left: -1px;
    height: 17px;
    background: #3F99ED;
    color: #fff;
    font-size: 10px;
    line-height: 8px;
    padding: 3px 5px;
    white-space: nowrap;
    display: block;
}
