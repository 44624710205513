.ptah-overlay-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1000;

    background: rgba(0, 0, 0, .4);
}

.ptah-overlay-modal .ptaa-modal {
    width: 60%;
    min-width: 480px;
    max-width: 1024px;
    background: #fff;
    max-height: 90%;
    min-height: 50%;

    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, .2);

    display: flex;
    flex-direction: column;
}

.ptah-overlay-modal .ptaa-modal header {
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    background: #eee;

    box-shadow: inset 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.ptaa-modal section {
    flex: 1;
    overflow: hidden;
    position: relative;
}

.ptaa-modal section .ptaa-scroll-container {
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
    overflow: auto;
}

.ptah-overlay-modal .ptaa-modal header .close {
    cursor: pointer;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: background-color 0.25s;
}

.ptah-overlay-modal .ptaa-modal header .close:hover {
    background: rgba(0, 0, 0, 0.1);
}

.ptah-overlay-modal .ptaa-modal section {
    padding: 20px;
}

.ptah-overlay-modal .ptaa-modal .ptaa-modal-title {
    color: #666;
    font-weight: bold;
    text-transform: uppercase;
}
