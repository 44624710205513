.tools {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 20px;
    margin-left: 20px;
}

@media only screen and (max-width: 800px) {
    .tools {
        display: none;
    }
}

.tools li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    cursor: pointer;

    width: 50px;
    height: 50px;
    border-radius: 50%;

    transition: background-color 0.25s;
}

.tools li svg {
    fill: #838687;
}

.tools li.selected {
    background: #35363a;
    cursor: default;
}

.tools li.selected svg {
    fill: #fff;
}
